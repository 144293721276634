function LoadingDetails() {
    return (
        <div className="bg-gray-400 border border-gray-400 rounded-lg my-5 md:my-10 animate-pulse">
            <h3 className="px-5 py-3 font-bold text-gray-400">Details</h3>

            <div className="bg-white">
                <div className="px-5 pt-5 md:px-10 md:pt-10 space-y-5 text-base">
                    <div className="h-7 w-56 rounded-md bg-gray-400" />
                    <div className="h-7 w-72 rounded-md bg-gray-400" />
                    <div className="h-7 w-64 rounded-md bg-gray-400" />
                    <div className="h-7 w-52 mb-10 rounded-md bg-gray-400" />
                </div>
            </div>

            <div className="flex justify-end rounded-b-lg bg-white pr-5 py-5">
                <div className="h-10 w-24 rounded-md bg-gray-400" />
            </div>
        </div>
    )
}

export default LoadingDetails;
