import { Link } from "react-router-dom";
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { useAppSelector } from "../../../app/hooks";
import { selectBasketQuantity } from "../../basket/state/BasketSlice";

function BasketWidget() {
  const basketTotal = useAppSelector(state => state.basket.total);

  const basketQuantity = useAppSelector(state => selectBasketQuantity(state));

  return (
    <Link to={`/basket`} className="relative flex items-center text-white font-bold cursor-pointer">
      <ShoppingBasketIcon />
      <div className="absolute top-0 left-4 flex items-center justify-center bg-[#7D84B2] rounded-full w-4 h-4 text-xs text-center font-normal">
        {basketQuantity}
      </div>
      <div className="ml-3">
        £{basketTotal.toFixed(2)}
      </div>
    </Link>
  )
}

export default BasketWidget;
