type Props = {
    title: string,
    imageClass: string
}

export default function Hero({title, imageClass}: Props) {
    return (
        <div className={`h-52 md:h-72 w-full bg-cover bg-center ${imageClass}`}>
            <div className="flex items-center bg-[#7D84B2]/40 w-full h-full">
                <div className="w-full max-w-6xl mx-auto text-center p-5 text-white font-semibold text-3xl md:text-5xl">
                    {title}
                </div>
            </div>
        </div>
    )
}
