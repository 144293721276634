import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks'
import { AppDispatch } from '../app/store';
import ProductLine from '../features/basket/components/ProductLine';
import { clearBasket } from '../features/basket/state/BasketSlice';
import { useGetProductsQuery } from '../features/product/api/api';

let dispatch: AppDispatch;

function doClearBasket() {
  dispatch(clearBasket({}));
}

function Basket() {
  dispatch = useAppDispatch();

  const basketProducts = useAppSelector(state => state.basket.products);
  const products = useGetProductsQuery();
  const basketTotal = useAppSelector(state => state.basket.total);

  return (
    <div className="w-full max-w-6xl mx-auto px-5">
      <h2 className="text-3xl font-bold my-10 text-[#7D84B2]">Basket</h2>

      {
        basketProducts.length === 0 && (
          <p>Basket is empty!</p>
        )
      }

      {
        basketProducts.length > 0 && (
          <>
            <div className="flex flex-col">
              <div className="flex my-3">
                <div className="flex flex-col md:flex-row w-3/6 md:w-9/12">
                  <div className="w-1/6 md:w-1/3"></div>
                  <div className="w-5/6 md:w-2/3 font-semibold text-sm md:text-xl">
                    Description
                  </div>
                </div>
                <div className="w-2/6 md:w-2/12 font-semibold text-sm md:text-xl text-center">
                  Quantity
                </div>
                <div className="w-1/6 md:w-1/12 font-semibold text-sm md:text-xl text-right">
                  Price
                </div>
              </div>

              {
                basketProducts.map((basketProduct, id) => {
                  const product = products.data?.products?.find(item => item.id === basketProduct.id);

                  return (
                    <ProductLine
                      key={product?.id}
                      id={product?.id || ""}
                      name={product?.name || ""}
                      description={product?.description || ""}
                      image={product?.image || ""}
                      price={product?.price || 0.0}
                    />
                  )
                })
              }
            </div>

            <div className="flex items-center justify-end space-x-5">
              <p className="font-semibold">
                TOTAL:
              </p>
              <p>
                £{basketTotal.toFixed(2)}
              </p>
            </div>

            <div className="flex justify-end py-5 space-x-4">
              <button className="text-[#8E9DCC] border border-[#8E9DCC] hover:bg-[#8E9DCC] hover:text-white items-end rounded-lg py-1 w-24 font-normal" onClick={() => doClearBasket()}>
                Clear
              </button>
              <Link to={`/checkout`}>
                <button className="text-[#8E9DCC] border border-[#8E9DCC] hover:bg-[#8E9DCC] hover:text-white items-end rounded-lg py-1 w-24 font-normal">
                  Checkout
                </button>
              </Link>
            </div>
          </>
        )
      }

    </div>
  )
}

export default Basket