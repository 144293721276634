import React from 'react'
import LoadingProduct from './LoadingProduct'

function LoadingProducts() {
    return (
        <>
            <LoadingProduct/>
            <LoadingProduct/>
            <LoadingProduct/>
            <LoadingProduct/>
            <LoadingProduct/>
            <LoadingProduct/>
        </>
    )
}

export default LoadingProducts