import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { AppDispatch } from "../../../app/store";
import { Product } from "../../product/types/Types";
import { addProduct, removeProduct, selectBasketProductQuantity } from "../state/BasketSlice";

let dispatch: AppDispatch;

function addToBasket(id: string, price: number) {
    dispatch(addProduct({ id, price }));
}

function removeFromBasket(id: string, price: number) {
    dispatch(removeProduct({ id, price }));
}

function ProductLine({ id, name, description, image, price }: Product) {
    dispatch = useAppDispatch();

    const quantity = useAppSelector(state => selectBasketProductQuantity(state, id));

    const linePrice = price * quantity;

    return (
        <div className="flex flex-row items-center my-3">
            <div className="flex flex-col md:flex-row w-3/6 md:w-9/12">
                <div className="flex items-center justify-center md:w-1/3">
                    <img
                        className="object-cover w-24"
                        src={image}
                        alt=""
                    />
                </div>
                <div className="flex flex-col md:col-span-3 justify-start md:w-2/3 text-sm md:text-base">
                    <div className="font-semibold">
                        {name}
                    </div>
                    <div>
                        {description}
                    </div>
                </div>
            </div>
            <div className="flex justify-center items-center w-2/6 md:w-2/12">
                <button onClick={() => addToBasket(id, price)} className="text-[#8E9DCC] border border-[#8E9DCC] hover:bg-[#8E9DCC] hover:text-white font-normal items-end rounded-lg py-1 my-2 w-8">
                    +
                </button>

                <p className="w-8 text-sm md:text-base font-normal text-center">{quantity}</p>

                <button onClick={() => removeFromBasket(id, price)} className="text-[#8E9DCC] border border-[#8E9DCC] hover:bg-[#8E9DCC] hover:text-white font-normal items-end rounded-lg py-1 my-2 w-8">
                    -
                </button>
            </div>
            <div className="text-right text-sm md:text-base w-1/6 md:w-1/12">
                £{linePrice.toFixed(2)}
            </div>
        </div >
    )
}

export default ProductLine;
