import { api } from "../../api/api";
import { Account } from "../types/types";

const productApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAccount: builder.query<Account, string>({
            query: (id) => `account/${id}`
        }),
    }),
    overrideExisting: false,
})

export const { useGetAccountQuery } = productApi;
