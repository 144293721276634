import { Outlet } from 'react-router'
import Footer from '../features/footer/components/Footer'
import Header from '../features/header/components/Header'
import { useAuth0 } from '@auth0/auth0-react';
import { auth0User } from "../features/user/state/userSlice";
import { useAppDispatch } from '../app/hooks';
import { User } from '@auth0/auth0-spa-js';

function DefaultLayout() {
  const dispatch = useAppDispatch();
  const { user, isLoading } = useAuth0();

  if (user != null) {
    dispatch(auth0User({
      ...user
    }));
  } 

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="font-extralight text-lg bg-slate-200">
        <Header />

        <div className="pt-[116px] md:pt-[146px] pb-10">
          <Outlet />
        </div>

        <Footer />
      </div>
  )
}

export default DefaultLayout