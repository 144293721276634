import { api } from "../../api/api";
import { AllProductResponse } from "../types/Types";

const productApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getProducts: builder.query<AllProductResponse, void>({
            query: () => "product"
        }),
    }),
    overrideExisting: false,
})

export const { useGetProductsQuery } = productApi;
