import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from 'react'
import BasketWidget from './BasketWidget'
import LocalPizzaIcon from '@mui/icons-material/LocalPizza';

const Header = () => {
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();

  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const toogleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen)
  }

  const closeMenu = () => {
    setHamburgerOpen(false);
  }

  let menuClasses = hamburgerOpen ? "flex" : "hidden md:flex";
  let burger1Classes = hamburgerOpen ? "transform rotate-45" : "transform rotate-0";
  let burger2Classes = hamburgerOpen ? "translate-x-full opacity-0" : "translate-x-0 opacity-1";
  let burger3Classes = hamburgerOpen ? "transform -rotate-45" : "transform rotate-0";

  return (
    <div className="fixed w-full top-0 text-white h-[118px] md:h-[148px] z-30">
      <div className="bg-[#7D84B2]">
        <div className="flex justify-between w-full max-w-6xl mx-auto p-5">
          <div className="flex items-center">
            <LocalPizzaIcon />
            <Link to={`/`}>
              <h1 className="text-lg md:text-2xl font-work-sans font-extrabold uppercase mx-3">Serverless Pizza</h1>
            </Link>
          </div>

          <div onClick={toogleHamburger} className="w-8 h-8 flex flex-col flex-nowrap justify-around cursor-pointer md:hidden z-50">
            <div className={`${burger1Classes} w-8 h-1 bg-white rounded-[10px] origin-[1px] transition-all`} />
            <div className={`${burger2Classes} w-8 h-1 bg-white rounded-[10px] origin-[1px] transition-all`} />
            <div className={`${burger3Classes} w-8 h-1 bg-white rounded-[10px] origin-[1px] transition-all`} />
          </div>

          <div className={`${menuClasses} flex-col overflow-y-scroll pt-24 pb-12 md:pl-5 m-0 bg-[#7D84B2] left-0 fixed h-screen w-screen uppercase space-y-4 z-10 text-xl font-semibold pl-16 pr-16 md:items-center md:space-y-0 md:space-x-4 md:p-3 md:h-full md:w-fit md:overflow-hidden md:flex-row md:relative md:top-auto md:left-auto md:bg-[#fff]/0 md:border-none`}>
            <Link className="md:hover:border-b-2 border-[#8E9DCC]" to={`/`} onClick={closeMenu}>Home</Link>
            <Link className="md:hover:border-b-2 border-[#8E9DCC]" to={`/about`} onClick={closeMenu}>About</Link>
            {
              (isAuthenticated && (
                <>
                  <Link className="md:hover:border-b-2 border-[#8E9DCC]" to={`/myaccount`} onClick={closeMenu}>My Account</Link>

                  <button className="text-white border border-[#8E9DCC] hover:bg-[#8E9DCC] hover:text-white font-normal items-end rounded-lg py-1 my-2 w-24" onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
                    Log Out
                  </button>
                </>
              ))
            }
            {
              (!isAuthenticated && (
                <button className="text-white border border-[#8E9DCC] hover:bg-[#8E9DCC] hover:text-white font-normal items-end rounded-lg py-1 my-2 w-24" onClick={() => loginWithRedirect()}>
                  Log In
                </button>
              ))
            }
          </div>
        </div>

      </div>
      <div className="bg-[#8E9DCC]">
        <div className="flex items-center justify-end w-full max-w-6xl mx-auto p-2 pr-4 z-5">
          <BasketWidget />
        </div>
      </div>
    </div>
  );
};

export default Header;
