import { Account } from "../types/types"

type Props = {
    account: Account
}

function Details({ account }: Props) {
    const { id, name, address, email, phone } = account;

    return (
        <div className="bg-[#7D84B2] border border-[#7D84B2] rounded-lg my-5 md:my-10">
            <h3 className="px-5 py-3 font-bold text-white">Details</h3>

            <div className="bg-white">
                <div className="px-5 pt-5 md:px-10 md:pt-10 space-y-3 text-base">
                    <div className="flex flex-col space-x-5">
                        <p className="font-semibold">Name:</p>
                        <p>{name}</p>
                    </div>

                    <div className="flex flex-col space-x-5">
                        <p className="font-semibold">Address:</p>
                        <p>{address}</p>
                    </div>

                    <div className="flex flex-col space-x-5">
                        <p className="w-24 font-semibold">e-mail:</p>
                        <p className="break-words">{email}</p>
                    </div>

                    <div className="flex flex-col space-x-5">
                        <p className="font-semibold">Phone:</p>
                        <p>{phone}</p>
                    </div>
                </div>
            </div>

            <div className="flex justify-end rounded-b-lg bg-white pr-5 pb-5 -mt-1">
                <button className="text-[#8E9DCC] bg-white border border-[#8E9DCC] hover:bg-[#8E9DCC] hover:text-white items-end rounded-lg py-1 w-24 font-normal">
                    Edit
                </button>
            </div>
        </div>
    )
}

export default Details