import Details from '../features/account/components/Details'
import OrderHistory from '../features/account/components/OrderHistory'
import PaymentMethods from '../features/account/components/PaymentMethods'
import { withAuthenticationRequired } from '@auth0/auth0-react';
import LoadingDetails from '../features/account/components/LoadingDetails';
import { useGetAccountQuery } from '../features/account/api/api';
import { Account } from "../features/account/types/types";
import { useAppSelector } from '../app/hooks';
import { selectAuth0User } from '../features/user/state/userSlice';
import { User } from '@auth0/auth0-spa-js';
import LoadingPayments from '../features/account/components/LoadingPayments';
import LoadingOrderHistory from '../features/account/components/LoadingOrderHistory';

function MyAccount() {
  const auth0User = useAppSelector(selectAuth0User) as unknown as User;
  const id = auth0User.sub?.substring(6) ?? "";
  const account = useGetAccountQuery(id);

  const emptyAccount: Account = {
    id: "",
    name: "",
    address: "",
    email: "",
    phone: "",
    payments: [],
    orderHistory: []
  };

  const data = account.data ?? emptyAccount;

  let content;
  if (account.isFetching) {
    content = (
      <>
        <LoadingDetails/>
        <LoadingPayments/>
        <LoadingOrderHistory/>
      </>
    )
  } else if (account.isSuccess) {
    content = (
      <>
        <Details account={account.data ?? emptyAccount} />
        <PaymentMethods payments={data.payments}/>
        <OrderHistory orders={data.orderHistory} />
      </>
    )
  } else if (account.isError) {
    console.log(account.error);
    content = <p>Error loading account</p>
  }

  return (
    <div className="w-full max-w-6xl mx-auto px-5">
      <h2 className="text-3xl font-bold my-10 text-[#7D84B2]">My Account</h2>

      {content}
    </div>
  )
}

export default withAuthenticationRequired(MyAccount, {
  onRedirecting: () => <div>Loading...</div>,
});
