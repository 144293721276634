function LoadingPayments() {
    return (
        <div className="bg-gray-400 border border-gray-400 rounded-lg my-5 md:my-10 animate-pulse">
            <h3 className="px-5 py-3 font-bold text-gray-400">Payment Methods</h3>

            <div className="bg-white grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-10 p-5 md:p-10">
                <div className="bg-gray-400 border border-gray-400 rounded-lg">
                    <h3 className="px-5 py-3 font-bold text-gray-400">Card</h3>

                    <div className="bg-white">
                        <div className="p-5 space-y-3 text-base">
                            <div className="flex justify-between">
                                <div className="h-7 w-20 rounded-md bg-gray-400" />
                                <div className="h-7 w-32 rounded-md bg-gray-400" />
                            </div>
                            <div className="flex justify-between">
                                <div className="h-7 w-24 rounded-md bg-gray-400" />
                                <div className="h-7 w-16 rounded-md bg-gray-400" />
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-end rounded-b-lg bg-white pr-5 pb-5 -mt-1">
                        <div className="h-10 w-24 rounded-md bg-gray-400" />
                    </div>
                </div>
                <div className="bg-gray-400 border border-gray-400 rounded-lg">
                    <h3 className="px-5 py-3 font-bold text-gray-400">Card</h3>

                    <div className="bg-white">
                        <div className="p-5 space-y-3 text-base">
                            <div className="flex justify-between">
                                <div className="h-7 w-20 rounded-md bg-gray-400" />
                                <div className="h-7 w-32 rounded-md bg-gray-400" />
                            </div>
                            <div className="flex justify-between">
                                <div className="h-7 w-24 rounded-md bg-gray-400" />
                                <div className="h-7 w-16 rounded-md bg-gray-400" />
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-end rounded-b-lg bg-white pr-5 pb-5 -mt-1">
                        <div className="h-10 w-24 rounded-md bg-gray-400" />
                    </div>
                </div>
            </div>

            <div className="flex justify-end rounded-b-lg bg-white pr-5 pb-5 -mt-1">
                <div className="h-10 w-24 rounded-md bg-gray-400" />
            </div>
        </div>
    )
}

export default LoadingPayments;
