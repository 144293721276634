import { Order } from "../types/types"
import OrderComponent from "./Order"

type Props = {
    orders: Order[]
}

function OrderHistory({ orders }: Props) {
    return (
        <div className="bg-[#7D84B2] border border-[#7D84B2] rounded-lg my-5 md:my-10">
            <h3 className="px-5 py-3 font-bold text-white">Order History</h3>

            <div className="bg-white px-5 pt-5 pb-1 md:px-10 md:pt-10 rounded-b-lg">
                <OrderComponent
                    key="1"
                    date="Monday 22nd March 2023 - 18:33"
                    products={['Farmhouse']}
                    price="£11.99"
                />

                <OrderComponent
                    key="2"
                    date="Saturday 2nd January 2023 - 17:25"
                    products={['Might Meaty', 'Veggie Supreme']}
                    price="£19.99"
                />
            </div>
        </div>
    )
}

export default OrderHistory