import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    auth0User: null
  },
  reducers: {
    auth0User: (state, action) => {
      state.auth0User = action.payload;
    },
    logout: (state) => {
      state.auth0User = null;
    }
  }
});

export const { auth0User, logout } = userSlice.actions;

export const selectAuth0User = (state: RootState) => state.user.auth0User;

export default userSlice.reducer;
