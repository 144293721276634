type Props = {
    name: string,
    lastFourDigits: string,
    expiry: string
}

function PaymentMethod({ name, lastFourDigits, expiry }: Props) {
    return (
        <div className="bg-[#7D84B2] border border-[#7D84B2] rounded-lg">
            <h3 className="px-5 py-3 font-bold text-white">{name}</h3>

            <div className="bg-white">
                <div className="p-5 space-y-3 text-base">
                    <div className="flex justify-between">
                        <p className="font-semibold">Number:</p>
                        <p>**** **** **** {lastFourDigits}</p>
                    </div>
                    <div className="flex justify-between">
                        <p className="font-semibold">Expiry Date:</p>
                        <p>{expiry}</p>
                    </div>
                </div>
            </div>

            <div className="flex justify-end rounded-b-lg bg-white pr-5 pb-5 -mt-1">
                <button className="text-[#8E9DCC] bg-white border border-[#8E9DCC] hover:bg-[#8E9DCC] hover:text-white items-end rounded-lg py-1 w-24 font-normal">
                    Delete
                </button>
            </div>
        </div>
    )
}

export default PaymentMethod