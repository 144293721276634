type Props = {
    date: string,
    products: string[],
    price: string
}

function Order({ date, products, price }: Props) {
    const productList = products.map(item =>
        <>
            <img
                className="object-cover w-28"
                src="https://cdn.dominosmedia.co.uk/i/dominos/1500-piz-original-cheese-and-tomato?fmt=auto&layer0=[pecrop=678,932,0,255&w=360]"
                alt=""
            />
            <p className="">
                {item}
            </p>
            <p className="text-center">
                1
            </p>
            <p className="text-right">
                £9.99
            </p>
        </>
    );

    return (
        <div className="bg-[#7D84B2] border border-[#7D84B2] rounded-lg mb-5 md:mb-10">
            <h3 className="px-5 py-3 font-bold text-white">{date}</h3>

            <div className="bg-white p-5">
                <div className="grid grid-cols-4 gap-5 items-center">
                    <div>&nbsp;</div>
                    <div className="font-semibold">
                        Description
                    </div>
                    <div className="font-semibold text-center">
                        Quantity
                    </div>
                    <div className="font-semibold text-right">
                        Price
                    </div>

                    {productList}
                </div>

                <div className="flex items-center justify-end space-x-5">
                    <p className="font-semibold">
                        TOTAL:
                    </p>
                    <p>
                        {price}
                    </p>
                </div>
            </div>

            <div className="flex justify-end rounded-b-lg bg-white pr-5 pb-5 -mt-1">
                <button className="text-[#8E9DCC] bg-white border border-[#8E9DCC] hover:bg-[#8E9DCC] hover:text-white items-end rounded-lg py-1 w-24 font-normal">
                    Reorder
                </button>
            </div>
        </div>
    )
}

export default Order