import { Payment } from "../types/types"
import PaymentMethod from "./PaymentMethod"

type Props = {
    payments: Payment[]
}

function PaymentMethods({ payments }: Props) {
    return (
        <div className="bg-[#7D84B2] border border-[#7D84B2] rounded-lg my-5 md:my-10">
            <h3 className="px-5 py-3 font-bold text-white">Payment Methods</h3>

            <div className="bg-white grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-10 p-5 md:p-10">
                <PaymentMethod
                    name="HSBC Credit Card"
                    lastFourDigits="1234"
                    expiry="11/25"
                />

                <PaymentMethod
                    name="Joint Debit Card"
                    lastFourDigits="4545"
                    expiry="05/27"
                />
            </div>

            <div className="flex justify-end rounded-b-lg bg-white pr-5 pb-5 -mt-1">
                <button className="text-[#8E9DCC] bg-white border border-[#8E9DCC] hover:bg-[#8E9DCC] hover:text-white items-end rounded-lg py-1 w-24 font-normal">
                    Add
                </button>
            </div>
        </div>
    )
}

export default PaymentMethods