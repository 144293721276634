import Hero from '../components/Hero'
import { useGetProductsQuery } from '../features/product/api/api';
import LoadingProducts from "../features/product/components/LoadingProducts";
import BasketProductComponent from '../features/product/components/Product';

function Home() {
  const allProducts = useGetProductsQuery();

  let content;
  if (allProducts.isFetching) {
    content = <LoadingProducts/>
  } else if (allProducts.isSuccess) {
    content = allProducts.data?.products.map(product => (
      <BasketProductComponent
        key={product.id}
        id={product.id}
        name={product.name}
        description={product.description}
        image={product.image}
        price={product.price}
      />
    ))
  } else if (allProducts.isError) {
    console.log(allProducts.error);
    content = <p>Error loading products</p>
  }

  return (
    <div className="h-full">
      <Hero 
        title="The best pizza in the world!"
        imageClass="bg-hero"
      />
      <div className="w-full max-w-6xl mx-auto px-5">
        <h2 className="text-3xl font-bold my-10 text-[#7D84B2]">Pizzas</h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
          {content}
        </div>
      </div>
    </div>
  )
}

export default Home;
