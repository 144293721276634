import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const origin = window.location.origin;
let domain = "";
if (origin.startsWith("http://")) {
  domain = "http://api." + origin.substring(7);
} else {
  domain = "https://api." + origin.substring(8);
}

if (domain.indexOf("localhost") > 0) {
  domain = "https://api.dev.nonprod.serverlesspizza.com";
}

export const api = createApi({
    baseQuery: fetchBaseQuery({ baseUrl: domain }),
    reducerPath: "api",
    endpoints: (builder) => ({})
})
