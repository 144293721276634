import React from 'react'

function About() {
  return (
    <div className="w-full max-w-6xl mx-auto px-5 space-y-4">
      <h2 className="text-3xl font-bold my-10 text-[#7D84B2]">
        About
      </h2>
      <p className="">
        This is Serverless Pizza. A fictitious site created by <a href="https://ian.collington.me/" target="_blank">Ian Collington</a> to learn AWS serverless and React.
      </p>

      <h2 className="text-2xl font-bold my-2 text-[#7D84B2]">
        Architecture
      </h2>
      <img src="/images/architecture.png" alt="Architecture" />
      <p>
        This website uses AWS Serverless functions along with Auth0 for authenication.
      </p>

      <h2 className="text-2xl font-bold my-2 text-[#7D84B2]">
        Components
      </h2>

      <h3 className="text-xl font-bold my-2 text-[#7D84B2]">
        Website
      </h3>
      <p>
        The website is built using React. Tailwind CSS is used for the styling and the Auth0 SDK is used for the authenication.
      </p>

      <h3 className="text-xl font-bold my-2 text-[#7D84B2]">
        Product Service
      </h3>
      <p>
        The Product service is responsible for retrieving the products from the database.
      </p>

      <h3 className="text-xl font-bold my-2 text-[#7D84B2]">
        Account Service
      </h3>
      <p>
        The Account service is responsible for the customer accounts. Personal details are stored here.
      </p>

      <h3 className="text-xl font-bold my-2 text-[#7D84B2]">
        Order Service
      </h3>
      <p>
        The Order service is responsible for the placing of customer orders. Each order is linked to an Account.
      </p>

      <h3 className="text-xl font-bold my-2 text-[#7D84B2]">
        Auth Service
      </h3>
      <p>
        The Auth service is responsible for the integrations with Auth0. It is used to provide callback endpoints from Auth0. When a new user signs up, a new Account is created via the Account service for example.
      </p>
    </div>
  )
}

export default About