function LoadingOrderHistory() {
    return (
        <div className="bg-gray-400 border border-gray-400 rounded-lg my-5 md:my-10 animate-pulse">
            <h3 className="px-5 py-3 font-bold text-gray-400">Order History</h3>

            <div className="bg-white px-5 pt-5 pb-1 md:px-10 md:pt-10 rounded-b-lg">
                <div className="bg-gray-400 border border-gray-400 rounded-lg mb-5 md:mb-10">
                    <h3 className="px-5 py-3 font-bold text-gray-400">Order History</h3>

                    <div className="bg-white p-5">
                        <div className="grid grid-cols-4 gap-5 items-center">
                            <div></div>
                            <div className="h-7 w-24 rounded-md bg-gray-400" />
                            <div className="flex justify-center">
                                <div className="h-7 w-20 rounded-md bg-gray-400" />
                            </div>
                            <div className="flex justify-end">
                                <div className="h-7 w-20 rounded-md bg-gray-400" />
                            </div>

                            <div className="h-24 w-24 rounded-md bg-gray-400" />
                            <div className="h-7 w-22 rounded-md bg-gray-400" />
                            <div className="flex justify-center">
                                <div className="h-7 w-10 rounded-md bg-gray-400" />
                            </div>
                            <div className="flex justify-end">
                                <div className="h-7 w-14 rounded-md bg-gray-400" />
                            </div>

                            <div className="h-24 w-24 rounded-md bg-gray-400" />
                            <div className="h-7 w-22 rounded-md bg-gray-400" />
                            <div className="flex justify-center">
                                <div className="h-7 w-10 rounded-md bg-gray-400" />
                            </div>
                            <div className="flex justify-end">
                                <div className="h-7 w-14 rounded-md bg-gray-400" />
                            </div>
                        </div>

                        <div className="flex items-center justify-end space-x-5">
                            <div className="h-7 w-24 rounded-md bg-gray-400" />
                            <div className="h-7 w-24 rounded-md bg-gray-400" />
                        </div>
                    </div>

                    <div className="flex justify-end rounded-b-lg bg-white pr-5 pb-5 -mt-1">
                        <div className="h-10 w-24 rounded-md bg-gray-400" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoadingOrderHistory;
