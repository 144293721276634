import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { AppDispatch } from "../../../app/store";
import { addProduct, removeProduct, selectBasketProductQuantity } from "../../basket/state/BasketSlice";
import { Product } from "../../product/types/Types";

let dispatch: AppDispatch;

function addToBasket(id: string, price: number) {
    dispatch(addProduct({id, price}));
}

function removeFromBasket(id: string, price: number) {
    dispatch(removeProduct({id, price}));
}

function ProductComponent({ id, name, description, image, price }: Product) {
    dispatch = useAppDispatch();

    const quantity = useAppSelector(state => selectBasketProductQuantity(state, id));

    return (
        <div className="flex md:flex-col justify-between w-full bg-white md:h-96 -z-1">
            <div className="overflow-hidden">
                <img className="duration-500 transition-all md:hover:scale-125 md:w-[100%] h-44 md:h-56 object-cover object-center " src={image} alt={name} />
            </div>
            <div className="flex flex-col justify-between p-3 w-full space-y-2 md:h-2/5">
                <h3 className="text-sm md:text-base font-semibold">{name}</h3>
                <p className="text-sm font-extralight">
                    {description}
                </p>
                <div className="flex items-center justify-between">
                    <p className="text-sm font-semibold">
                        £{price}
                    </p>

                    <div className="flex justify-between items-center">
                        <button onClick={() => addToBasket(id, price)} className="text-[#8E9DCC] border border-[#8E9DCC] hover:bg-[#8E9DCC] hover:text-white font-normal items-end rounded-lg py-1 my-2 w-8">
                            +
                        </button>

                        <p className="w-8 text-base font-normal text-center">{quantity}</p>

                        <button onClick={() => removeFromBasket(id, price)} className="text-[#8E9DCC] border border-[#8E9DCC] hover:bg-[#8E9DCC] hover:text-white font-normal items-end rounded-lg py-1 my-2 w-8">
                            -
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductComponent;
