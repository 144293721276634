import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { BasketState } from "../types/Types";

const initialState: BasketState = {
  products: [],
  total: 0.0,
};

export const basketSlice = createSlice({
  name: "basket",
  initialState,
  reducers: {
    addProduct: (state, action) => {
      const { id, price } = action.payload;
      const product = state.products.find((product) => product.id === id);

      if (product) {
        product.quantity++;
      } else {
        state.products = [
          ...state.products,
          {
            id: id,
            quantity: 1,
          },
        ];
      }

      state.total += price;
    },
    removeProduct: (state, action) => {
      const { id, price } = action.payload;
      const product = state.products.find((product) => product.id === id);

      if (product) {
        if (product.quantity > 1) {
          product.quantity--;
        } else {
          state.products = state.products.filter(
            (product) => product.id !== id
          );
        }
        state.total -= price;

        if(state.total < 0) {
          state.total = 0.0;
        }
      }
    },
    clearBasket: (state, action) => {
      state.products = [];
      state.total = 0.0;
    },
  },
});

export const { addProduct, removeProduct, clearBasket } = basketSlice.actions;

export const selectBasket = (state: RootState) => state.basket;

export const selectBasketProductQuantity = (state: RootState, id: string) => {
  const product = state.basket.products.find((item) => item.id === id);

  return product?.quantity ? product.quantity : 0;
};

export const selectBasketQuantity = (state: RootState) => {
  let quantity = 0;
  state.basket.products.forEach((product) => {
    quantity += product.quantity;
  });

  return quantity;
};

export default basketSlice.reducer;
