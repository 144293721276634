import { Link } from "react-router-dom"

function OrderPlaced() {
  return (
    <div className="w-full max-w-6xl mx-auto px-5">
      <h2 className="text-3xl font-bold my-10 text-[#7D84B2]">Order Placed</h2>

      <div className="space-y-5">
        <p>
          Thank for your order. Your pizza(s) will be with your shortly, although they won't as this is a fictitious site. Sorry!
        </p>

        <p>
          See the <Link className="border-b md:hover:border-b-2 border-[#8E9DCC]" to={`/myaccount`}>My Account</Link> page for your order history.
        </p>
      </div>
    </div>
  )
}

export default OrderPlaced
