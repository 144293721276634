import React from 'react'

function LoadingProduct() {
    return (
        <div className="flex md:flex-col justify-between w-full bg-white md:h-96 -z-1 animate-pulse">
            <div className="h-44 md:h-56 md:w-[100%] bg-gray-400" />
            <div className="flex flex-col justify-between p-3 w-full space-y-2 md:h-2/5">
                <div className="h-5 m-2 rounded-md bg-gray-400" />
                <div className="h-5 m-2 rounded-md bg-gray-400" />
                <div className="flex items-center justify-between h-20">
                    <div className="h-4 m-2 w-1/3 rounded-md bg-gray-400" />
                    <div className="h-8 m-2 w-1/3 rounded-md bg-gray-400" />
                </div>
            </div>
        </div>
    )
}

export default LoadingProduct