
function Checkout() {
  return (
    <div className="w-full max-w-6xl mx-auto px-5">
      <h2 className="text-3xl font-bold my-10 text-[#7D84B2]">Checkout</h2>

    </div>
  )
}

export default Checkout
