import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';

const Footer = () => {
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();

  return (
    <div className="flex flex-col items-center justify-between bg-[#2A324B] md:py-4 md:px-10 text-white">
      <div className="flex flex-col justify-between md:flex-row w-full max-w-6xl mx-auto p-5">
        <div className="flex flex-col space-y-2 p-4 md:px-10 md:w-1/3">
          <div className="flex items-center space-x-2">
            <ArrowRightOutlinedIcon />
            <Link className="" to={`/`}>Home</Link>
          </div>

          {
            (isAuthenticated && (
              <div className="flex items-center space-x-2">
                <ArrowRightOutlinedIcon />
                <Link className="" to={`/myaccount`}>My Account</Link>
              </div>
            ))
          }

          <div className="flex items-center space-x-2">
            <ArrowRightOutlinedIcon />
            <Link className="" to={`/basket`}>Basket</Link>
          </div>

          {
            (isAuthenticated && (
              <div className="flex items-center space-x-2">
                <ArrowRightOutlinedIcon />
                <a className="" href="#logout" onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
                  Log Out
                </a>
              </div>
            ))
          }

          {
            (!isAuthenticated && (
              <div className="flex items-center space-x-2">
                <ArrowRightOutlinedIcon />
                <a className="" href="#login" onClick={() => loginWithRedirect()}>Log In</a>
              </div>
            ))
          }

          <div className="flex items-center space-x-2">
            <ArrowRightOutlinedIcon />
            <Link className="" to={`/about`}>About</Link>
          </div>
        </div>

        <div className="text-base space-y-2 md:w-2/3 p-6 md:px-10">
          <p>
            Serverless Pizza is a fictitious site created by <a className="border-b" href="https://ian.collington.me/" target="_blank" rel="noreferrer">Ian Collington</a> to learn TypeScript by creating serverless backend services with AWS Lambda, using React for the frontend and AWS CDK for the build pipeline.
          </p>
        </div>
      </div>

      <div className="py-4 text-center">
        <p>
          &copy; Serverless Pizza
        </p>
      </div>
    </div>
  );
};

export default Footer;
