import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Pizzas from "./pages/Home";
import ErrorPage from "./error-page";
import About from "./pages/About";
import MyAccount from "./pages/MyAccount";
import DefaultLayout from "./layouts/DefaultLayout";
import { Auth0Provider } from "@auth0/auth0-react";
import Basket from "./pages/Basket";
import Checkout from "./pages/Checkout";
import OrderPlaced from "./pages/OrderPlaced";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <DefaultLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/",
          element: <Pizzas />,
        },
        {
          path: "/about",
          element: <About />,
        },
        {
          path: "/myaccount",
          element: <MyAccount />,
        },
        {
          path: "/basket",
          element: <Basket />,
        },
        {
          path: "/checkout",
          element: <Checkout />,
        },
        {
          path: "/orderPlaced",
          element: <OrderPlaced />,
        }
      ]
    }
  ]);

  return (
    <div>
      <Auth0Provider
        domain="serverlesspizza-dev.eu.auth0.com"
        clientId="iDKtgIQ6lXQ5x77M1CkTOqRCcswW3G7w"
        cacheLocation="localstorage"
        authorizationParams={{
          redirect_uri: window.location.origin
        }}
      >
        <RouterProvider router={router} />
      </Auth0Provider>
    </div>
  );
}

export default App;
